import * as coursesPerPageConstants from '../constants/coursesPerPage';

export const coursesPerPage = (id, keySearch) => {
  return {
    type: coursesPerPageConstants.GET_COURSES_PERPAGE,
    payload: {
      id,
      keySearch
    },
  }
}

export const coursesPerPageSuccess = data => {
  return {
    type: coursesPerPageConstants.GET_COURSES_PERPAGE_SUCCESS,
    payload: {
      data
    }
  }
}

export const coursesPerPageFailed = error => {
  return {
    type: coursesPerPageConstants.GET_COURSES_PERPAGE_FAILED,
    payload: {
      error
    }
  }
}
