import * as coursesRSPerPageConstants from '../constants/coursesRecentStudentsPerPage';

export const coursesRSPerPage = (id, keySearch) => {
  return {
    type: coursesRSPerPageConstants.GET_COURSERS_PERPAGE,
    payload: {
      id,
      keySearch
    },
  }
}

export const coursesRSPerPageSuccess = data => {
  return {
    type: coursesRSPerPageConstants.GET_COURSERS_PERPAGE_SUCCESS,
    payload: {
      data
    }
  }
}

export const coursesRSPerPageFailed = error => {
  return {
    type: coursesRSPerPageConstants.GET_COURSERS_PERPAGE_FAILED,
    payload: {
      error
    }
  }
}
