import { fork, take, call, put, delay, takeLatest, takeEvery, select } from 'redux-saga/effects';
import { fetchListTaskSuccess, fetchListTaskFailed, addTaskSucces, addTaskFailed, fetchListTask, updateTaskSucces, updateTaskFailed, deleteTaskSucces, deleteTaskFailed } from '../actions/task';
import { showLoading, hideLoading } from '../actions/ui'
import { hideModal } from '../actions/modal'
import { getList, addTask, updateTask, deleteTask } from './../apis/task';
import { loginLMS } from './../apis/loginLMS';
import { loginLMSSuccess, loginLMSFailed } from '../actions/loginLMS';
// Logout
import { logoutLMSAPI } from '../apis/logoutLMS';
import { logoutLMSSuccess, logoutLMSFailed } from '../actions/logoutLMS';
import * as logoutLMSType from '../constants/logoutLMS';
// End Logout

// report Conferences
import { conferencesAPI } from './../apis/reportConferences';
import { conferencesSuccess, conferencesFailed } from '../actions/reportConferences';
import * as conferencesTypes from './../constants/reportConferences';
// end report Conferences

// courses
import { coursesConferencesAPI } from './../apis/coursesConferences';
import { coursesActionSuccess, coursesActionFailed } from '../actions/coursesConferences';
import * as coureseConferencesType from './../constants/coureseConferences';
// end courses

// account terms
import { accountTermsAPI } from '../apis/accountTerms';
import { accountTermsActionSuccess, accountTermsActionFailed } from '../actions/accountTerms';
import * as accountTermsType from '../constants/accountTerms';
// end account terms

import { subAccounts } from './../apis/subAccounts';
import { subAccountsSuccess, subAccountsFailed } from '../actions/subAccounts';
import * as subAccountsTypes from './../constants/subAccounts';

import { courses } from './../apis/courses';
import { coursesSuccess, coursesFailed } from '../actions/courses';
import * as coursesTypes from './../constants/courses';

import { coursesOnlySuccess, coursesOnlyFailed } from '../actions/coursesOnly';

import { accounts } from './../apis/accounts';
import { accountsSuccess, accountsFailed } from '../actions/accounts';
import * as accountsTypes from './../constants/accounts';

import { coursesPerPage } from './../apis/coursesPerPage';
import { coursesPerPageSuccess, coursesPerPageFailed } from '../actions/coursesPerPage';
import * as coursesPerPageTypes from './../constants/coursesPerPage';

import { coursesOnlyPerPageSuccess, coursesOnlyPerPageFailed } from '../actions/coursesPerPageOnly';

import { STATUS_CODE, STATUSES } from './../constants';
import * as taskTypes from './../constants/task';
import * as loginLMSTypes from './../constants/loginLMS';

import { recentStudents } from './../apis/recentStudents';
import { recentStudentsSuccess, recentStudentsFailed } from '../actions/recentStudents';
import { coursesRSPerPageSuccess, coursesRSPerPageFailed } from '../actions/coursesRecentStudentsPerPage'

import { coursesSubmissionsAPI } from '../apis/coursesSubmissions';
import { coursesSubmissionsActionSuccess, coursesSubmissionsActionFailed } from '../actions/coursesSubmissions';

function* watchFetchListTaskAction() {
  while (true) {
    const action = yield take(taskTypes.FETCH_TASK);
    yield put(showLoading());
    const { params } = action.payload;
    // Block
    const res = yield call(getList, params);
    // Block  until call completed
    const { status, data } = res;
    if (status === STATUS_CODE.SUCCESS) {
      // dispatch action fetchListTaskSuccess
      yield put(fetchListTaskSuccess(data));
    } else {
      // dispatch action fetchListTaskFailed
      yield put(fetchListTaskFailed(data));
    }
    yield delay(1000);
    yield put(hideLoading());
  }
}

function* watchCreateTaskAction() {
  yield 2;
}

function* filterTaskSaga({ payload }) {
  //yield delay(500);
  const { keyword } = payload;
  yield put(
    fetchListTask({
      q: keyword,
    }),
  );
}

function* addTaskSaga({ payload }) {
  const { title, description } = payload;
  yield put(showLoading());

  const res = yield call(addTask, {
    title,
    description,
    status: STATUSES[0].value,
  })

  const { data, status } = res;
  if (status === STATUS_CODE.CREATED) {
    yield put(addTaskSucces(data));
    yield put(hideModal());
  } else {
    yield put(addTaskFailed(data));
  }
  yield delay(1000);
  yield put(hideLoading());
}

function* updateTaskSaga({ payload }) {
  const { title, description, status } = payload;
  const taskEditing = yield select(state => state.task.taskEditing);
  yield put(showLoading());
  const res = yield call(updateTask, { title, description, status }, taskEditing.id);
  const { data, status: statusCode } = res;
  if (statusCode === STATUS_CODE.SUCCESS) {
    yield put(updateTaskSucces(data));
    yield put(hideModal());
  } else {
    yield put(updateTaskFailed(data));
  }
  yield delay(1000);
  yield put(hideLoading());
}

function* deleteTaskSaga({ payload }) {
  const { taskId } = payload;
  yield put(showLoading());
  const res = yield call(deleteTask, taskId);
  const { data, status: statusCode } = res;
  if (statusCode === STATUS_CODE.SUCCESS) {
    yield put(deleteTaskSucces(taskId));
    yield put(hideModal());
  } else {
    yield put(deleteTaskFailed(data));
  }
  yield delay(1000);
  yield put(hideLoading());
}

function* loginLMSSaga({ payload }) {
  const { code, grant_type, client_id, client_secret, redirect_uri } = payload;
  const res = yield call(loginLMS, {
    code,
    grant_type,
    client_id,
    client_secret,
    redirect_uri,
  })

  const { data } = res;
  if (data.access_token) {
    yield put(loginLMSSuccess(data));
  } else {
    yield put(loginLMSFailed(data));
  }
}

// Logout
function* logoutLMSSaga({ payload }) {
  const { expire_sessions } = payload;
  const res = yield call(logoutLMSAPI, {
    expire_sessions,
  })

  const { data } = res;
  if (data) {
    yield put(logoutLMSSuccess(data));
  } else {
    yield put(logoutLMSFailed(data));
  }
}
//End Logout

// Conferences
function* conferencesSaga({ payload }) {
  try {
    const { state } = payload;
    const res = yield call(conferencesAPI, {
      state
    })
    const { data } = res;
    if (data.conferences.length > 0) {
      yield put(conferencesSuccess(data));
    } else {
      throw data;;
    }
  }
  catch (error) {
    yield put(conferencesFailed(error.message));
  }
}
// End Conferences

// Courses Conferences
function* coursesConferencesSaga({ payload }) {
  try {
    const { include } = payload;
    const res = yield call(coursesConferencesAPI, {
      include
    })
    const { data } = res;
    if (data.length > 0) {
      yield put(coursesActionSuccess(data));
    } else {
      throw data;
    }
  }
  catch (error) {
    yield put(coursesActionFailed(error.message));
  }
}
// End Courses Conferences

function* accountsSaga({ payload }) {
  try {
    const res = yield call(accounts, {})
    const { data } = res;
    if (data.length > 0) {
      yield put(accountsSuccess(data));
    } else {
      throw data;
    }
  }
  catch (error) {
    yield put(accountsFailed(error.message));
  }
}

function* subAccountsSaga({ payload }) {
  const { id } = payload;
  const { recursive } = payload
  const res = yield call(subAccounts, { id, recursive })
  const { data } = res;
  const dataAcc = yield select(state => state.accounts.dataAccounts);
  const filterArrSelect = dataAcc.filter(value => value.id === id);
  data.unshift(filterArrSelect[0]);

  if (data.length > 0) {
    yield put(subAccountsSuccess(data));
  } else {
    yield put(subAccountsFailed(data));
  }
}

function* coursesSaga({ payload }) {
  const { id, currentPage, keySearch } = payload;
  const res = yield call(courses, {
    id, currentPage, keySearch
  })

  const { data } = res;
  if (data) {
    yield put(coursesSuccess(data))

    const jsonData = [];
    for (const i in data) {

      if (data.hasOwnProperty(i)) {
        const idCourses = data[i].id;

        const resCoursesSubmissions = yield call(coursesSubmissionsAPI, { id: idCourses });

        const jsonDataCoursesSubmissions = {
          coursesID: idCourses,
          dataSubmissions: resCoursesSubmissions.data
        }

        jsonData.push({ jsonDataCoursesSubmissions });

        if (jsonData.length > 0) {
          if (jsonData.length === data.length) {
            yield put(coursesSubmissionsActionSuccess(jsonData));
          }
        } else {
          yield put(coursesSubmissionsActionFailed(jsonData));
        }
      }
    }
  } else {
    yield put(coursesFailed(data));
  }
}

function* coursesSagaRecentStudents({ payload }) {
  const { id, currentPage, keySearch } = payload;

  const res = yield call(courses, {
    id, currentPage, keySearch
  })

  const { data } = res;

  if (data) {
    yield put(coursesSuccess(data))

    const recentStudentsArr = [];
    for (const i in data) {

      if (data.hasOwnProperty(i)) {
        const idCourses = data[i].id;

        const resRecentStudents = yield call(recentStudents, { idCourses: idCourses });

        const jsonRecentStudents = {
          idCourses: resRecentStudents.config.idCourses,
          dataRecentStudent: resRecentStudents.data
        }

        recentStudentsArr.push(jsonRecentStudents)

        if (recentStudentsArr.length > 0) {
          if (recentStudentsArr.length === data.length) {
            yield put(recentStudentsSuccess(recentStudentsArr));
          }
        } else {
          yield put(recentStudentsFailed(recentStudentsArr));
        }
      }
    }
  } else {
    yield put(coursesFailed(data));
  }
}

function* coursesPerPageSaga({ payload }) {
  const { id, keySearch } = payload;
  const recentStudentsArr = [];
  const jsonData = [];
  const res = yield call(coursesPerPage, { id, keySearch })
  const { data } = res;

  if (data) {
    yield put(coursesPerPageSuccess(data))

    for (const i in data) {
      if (data.hasOwnProperty(i)) {
        const idCourses = data[i].id;
        // Recent Students
        const resRecentStudents = yield call(recentStudents, { idCourses })

        const jsonRecentStudents = {
          idCourses: resRecentStudents.config.idCourses,
          dataRecentStudent: resRecentStudents.data
        }

        recentStudentsArr.push(jsonRecentStudents)

        if (recentStudentsArr.length > 0) {
          if (recentStudentsArr.length === data.length) {
            yield put(coursesRSPerPageSuccess(recentStudentsArr));
          }
        } else {
          yield put(coursesRSPerPageFailed(recentStudentsArr))
        }
        // End Recent Students

        // Courses Submissions
        const resCoursesSubmissions = yield call(coursesSubmissionsAPI, { id: idCourses });

        const jsonDataCoursesSubmissions = {
          coursesID: idCourses,
          dataSubmissions: resCoursesSubmissions.data
        }

        jsonData.push({ jsonDataCoursesSubmissions });

        if (jsonData.length > 0) {
          if (jsonData.length === data.length) {
            yield put(coursesSubmissionsActionSuccess(jsonData));
          }
        } else {
          yield put(coursesSubmissionsActionFailed(jsonData));
        }
        // End Courses Submissions
      }
    }
  } else {
    yield put(coursesPerPageFailed(data));
  }
}

// account terms
function* accountTermsSaga({ payload }) {
  const { id } = payload;
  const res = yield call(accountTermsAPI, {
    id
  })
  const { data } = res;

  if (data) {
    yield put(accountTermsActionSuccess(data));
  } else {
    yield put(accountTermsActionFailed(data));
  }
}
// end account terms

function* coursesOnlySaga({ payload }) {
  const { id, currentPage, keySearch } = payload;
  const res = yield call(courses, {
    id, currentPage, keySearch
  })

  const { data } = res;
  if (data) {
    yield put(coursesOnlySuccess(data))

  } else {
    yield put(coursesOnlyFailed(data));
  }
}

function* coursesPerPageOnlySaga({ payload }) {
  const { id, keySearch } = payload;
  const res = yield call(coursesPerPage, {
    id, keySearch
  })

  const { data } = res;
  if (data) {
    yield put(coursesOnlyPerPageSuccess(data))

  } else {
    yield put(coursesOnlyPerPageFailed(data));
  }
}


function* rootSaga() {
  yield fork(watchFetchListTaskAction);
  yield fork(watchCreateTaskAction);
  yield takeLatest(taskTypes.FILTER_TASK, filterTaskSaga);
  yield takeEvery(taskTypes.ADD_TASK, addTaskSaga);
  yield takeLatest(taskTypes.UPDATE_TASK, updateTaskSaga);
  yield takeLatest(taskTypes.DELETE_TASK, deleteTaskSaga)
  yield takeLatest(loginLMSTypes.LOGIN_LMS, loginLMSSaga);
  // Logout
  yield takeLatest(logoutLMSType.LOGOUT_LMS, logoutLMSSaga);
  // Conferecnces
  yield takeLatest(conferencesTypes.GET_CONFERENCES, conferencesSaga);
  // Courses Conferecnces
  yield takeLatest(coureseConferencesType.GET_COURSES_CONFERENCES, coursesConferencesSaga);
  // Courses Per Page
  yield takeLatest(coursesPerPageTypes.GET_COURSES_PERPAGE, coursesPerPageSaga);
  // Export Report
  //yield takeLatest(exportReportTypes.GET_EXPORT_REPORT, exportReportSaga);
  //
  yield takeLatest(accountsTypes.GET_ACCOUNTS, accountsSaga);
  yield takeLatest(subAccountsTypes.GET_SUBACCOUNTS, subAccountsSaga);
  yield takeLatest(coursesTypes.GET_COURSES, coursesSaga);
  // Account Terms
  yield takeLatest(accountTermsType.GET_ACCOUNTS_TERMS, accountTermsSaga);

  // RecentStudents
  yield takeLatest(coursesTypes.GET_COURSES, coursesSagaRecentStudents)
  
  yield takeLatest(coursesTypes.GET_COURSES_ONLY, coursesOnlySaga)
  
  yield takeLatest(coursesPerPageTypes.GET_COURSES_PERPAGE_ONLY, coursesPerPageOnlySaga)
}

export default rootSaga;