import * as subAccountsConstants from './../constants/subAccounts';

const initialState = {
  dataSubAccounts: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case subAccountsConstants.GET_SUBACCOUNTS: {
      return {
        ...state,
      }
    }

    case subAccountsConstants.GET_SUBACCOUNTS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        dataSubAccounts: data,
      }
    }

    case subAccountsConstants.GET_SUBACCOUNTS_FAILED: {
      return {
        ...state,
        dataSubAccounts: []
      }
    }

    default:
      return state;
  }
}
export default reducer;
