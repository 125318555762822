import React, { useState } from 'react';
import { Layout, Menu } from 'antd';
import { NavLink } from 'react-router-dom';
import './index.scss';

const { Sider } = Layout;
const { SubMenu } = Menu;

const Sidebar = (props) => {

  const [collapsed, setCollapsed] = useState(false);

  const onCollapse = collapsed => {
    setCollapsed(collapsed);
  };

  const rootSubmenuKeys = ['sub1', 'sub2', 'sub4'];

  const [state, setState] = useState({
    openKeys: ['sub1'],
  })

  const onOpenChange = openKeys => {
    const latestOpenKey = openKeys.find(key => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setState({ ...state, openKeys });
    } else {
      setState({
        ...state,
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    }
  };

  return (
    <Sider breakpoint="lg" collapsible collapsedWidth="0" collapsed={collapsed} onCollapse={onCollapse}>
      <div className="logo" />
      <Menu theme="dark" defaultSelectedKeys={['/admin']} selectedKeys={[props.props.location.pathname]} mode="inline" openKeys={state.openKeys} onOpenChange={onOpenChange}>
        <Menu.Item key="/admin">
          <NavLink to="/admin">Trang quản trị</NavLink>
        </Menu.Item>

        <SubMenu key="sub1" title="Báo cáo">
          <Menu.Item key="/admin/report-conferences">
            <NavLink to="/admin/report-conferences">Lớp học trực tuyến</NavLink>
          </Menu.Item>

          <Menu.Item key="/admin/report-countstudents">
            <NavLink to="/admin/report-countstudents"> Số người học</NavLink>
          </Menu.Item>

          <Menu.Item key="/admin/report-courses">
            <NavLink to="/admin/report-courses"> Tình hình tham gia học</NavLink>
          </Menu.Item>

          <Menu.Item key="/admin/report-submission-assignments">
            <NavLink to="/admin/report-submission-assignments">Tình hình nộp bài</NavLink>
          </Menu.Item>
        </SubMenu>
      </Menu>
    </Sider>
  )
}

export default Sidebar;
