import { combineReducers } from 'redux';
import taskReducer from './task';
import uiReducer from './ui';
import modalReducer from './modal';
import loginLMSReducer from './loginLMS';
import logoutLMSReducer from './logoutLMS';
import subAccountsReducer from './subAccounts';
import reportConferencesReducer from './reportConferences';
import coursesConferencesReducer from './coursesConferences';
import accountTermsReducer from './accountTerms';
import coursesReducer from './courses';
import coursesPerPageReducer from './coursesPerPage';
import accountsReducer from './accounts';
import recentStudentsReducer from './recentStudents';
import coursesSubmissionsReducer from './coursesSubmissions';
import recentStudentsPerPageReducer from './coursesRSPerPage';
import coursesOnlyReducer from './coursesOnly';
import coursesPerPageOnlyReducer from './coursesPerPageOnly';

const rootReducer = combineReducers({
  task: taskReducer,
  ui: uiReducer,
  modal: modalReducer,
  loginLMS: loginLMSReducer,
  // Logout
  logoutLMS: logoutLMSReducer,
  // Conferences
  reportConferences: reportConferencesReducer,
  // Courses Conferences
  coursesConferences: coursesConferencesReducer,
  // Account Terms
  accountTerms: accountTermsReducer,
  // Courses Submissions
  coursesSubmissionsReducer,
  // Courses per_page=1000
  coursesPerPageReducer,
  coursesPerPage: coursesPerPageReducer,
  //
  subAccounts: subAccountsReducer,
  courses: coursesReducer,
  accounts: accountsReducer,
  recentStudents: recentStudentsReducer,
  recentStudentsPerPage: recentStudentsPerPageReducer,

  coursesOnly: coursesOnlyReducer,
  coursesPerPageOnly: coursesPerPageOnlyReducer
});

export default rootReducer;
