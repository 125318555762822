import * as coursesConstants from '../constants/coureseConferences';


const initialState = {
  dataCourses: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case coursesConstants.GET_COURSES_CONFERENCES: {
      return {
        ...state,
      }
    }

    case coursesConstants.GET_COURSES_CONFERENCES_SUCCESS: {
      const { data } = action.payload;
      return {
        dataCourses: data,
      }
    }

    case coursesConstants.GET_COURSES_CONFERENCES_FAILED: {
      const { error } = action.payload;
      console.log(error)
      return {
        ...state,
        error: error,
      }
    }

    default:
      return state;
  }
}
export default reducer;
