import * as accountsConstants from './../constants/accounts';

const initialState = {
  dataAccounts: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case accountsConstants.GET_ACCOUNTS: {
      return {
        ...state,
      }
    }

    case accountsConstants.GET_ACCOUNTS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        dataAccounts: data,
      }
    }

    case accountsConstants.GET_ACCOUNTS_FAILED: {
      const { error } = action.payload;
      console.log('Account Reducer Error:', error);
      return {
        ...state,
        error: error,
      }
    }

    default:
      return state;
  }
}
export default reducer;
