import * as coursesConstants from '../constants/courses';

export const courses = (id, currentPage, keySearch) => {
  return {
    type: coursesConstants.GET_COURSES,
    payload: {
      id,
      currentPage,
      keySearch,
    },
  }
}

export const coursesSuccess = data => {
  return {
    type: coursesConstants.GET_COURSES_SUCCESS,
    payload: {
      data
    }
  }
}

export const coursesFailed = error => {
  return {
    type: coursesConstants.GET_COURSES_FAILED,
    payload: {
      error
    }
  }
}
