import React from 'react';
import './index.scss';
import { Spin } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import * as uiActions from './../../actions/ui';

const GlobalLoading = (props) => {
  const { showLoading } = props;
  if (showLoading) {
    return (
      <div className="wrap-loading">
        <Spin className="loading" />
      </div>
    )
  }
  return null
}

const mapStateToProps = state => {
  return {
    showLoading: state.ui.showLoading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    uiActionsCreators: bindActionCreators(uiActions, dispatch)
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(GlobalLoading);
