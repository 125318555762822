import axiosService from '../commons/axiosService';
import { API_ENDPOINT_LMS } from '../constants';

export const coursesPerPage = (data) => {
  const token = JSON.parse(localStorage.getItem('checkLogin'));
  const url = data.keySearch ?
    `api/v1/accounts/${data.id}/courses?search_term=${data.keySearch}&completed=false&include[]=total_students&include[]=account_name&per_page=10000`
    :
    `api/v1/accounts/${data.id}/courses?completed=false&include[]=total_students&include[]=account_name&per_page=10000`

  const access_token = token ? token.access_token : null;

  const config = {
    headers: { Authorization: `Bearer ${access_token}` }
  };

  return axiosService.getConfig(`${API_ENDPOINT_LMS}/${url}`, data, config)
}
