import * as loginLMSConstants from './../constants/loginLMS';

export const loginLMS = (code, grant_type = "authorization_code", client_id = "10000000000003", client_secret = "vfS2pjYjHn0Q13vYkmQlMlP8g5ZNFZtuLzEfnddEqkY6rkWRF5JsxodwEXzMCQsD", redirect_uri = "https://lmsadmin.herapo.net") => {
  return {
    type: loginLMSConstants.LOGIN_LMS,
    payload: {
      code,
      grant_type,
      client_id,
      client_secret,
      redirect_uri,
    },
  }
}

export const loginLMSSuccess = data => {
  return {
    type: loginLMSConstants.LOGIN_LMS_SUCCESS,
    payload: {
      data
    }
  }
}

export const loginLMSFailed = error => {
  return {
    type: loginLMSConstants.LOGIN_LMS_FAILED,
    payload: {
      error
    }
  }
}
