import React, { useEffect }  from 'react';
import { Route } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Dashboard from './../../../components/Dashboard';


const AdminLayoutRoute = (props) => {
  let history = useHistory();

  useEffect(() => {
    if (localStorage.length === 0) {
      history.push("/")
    }
  }, [history])

  const { component: YourComponent, ...remainProps } = props;

  return (
    <Route
      {...remainProps} render={routeProps => {
        return (
          <Dashboard {...remainProps}>
            <YourComponent {...routeProps}/>
          </Dashboard>
        )
      }}
    />
  )
}

export default AdminLayoutRoute;
