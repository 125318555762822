export const FETCH_TASK = 'FETCH_TASK';
export const FETCH_TASK_SUCCESS = 'FETCH_TASK_SUCCESS';
export const FETCH_TASK_FAILED = 'FETCH_TASK_FAILED';

export const FILTER_TASK = 'FILTER_TASK';
export const FILTER_TASK_SUCCESS = 'FILTER_TASK_SUCCESS';

export const ADD_TASK = 'ADD_TASK';
export const ADD_TASK_SUCCES = 'ADD_TASK_SUCCES';
export const ADD_TASK_FAILED = 'ADD_TASK_FAILED';

export const SET_TASK_EDITTING = 'SET_TASK_EDITTING';

export const UPDATE_TASK = 'UPDATE_TASK';
export const UPDATE_TASK_SUCCES = 'UPDATE_TASK_SUCCES';
export const UPDATE_TASK_FAILED = 'UPDATE_TASK_FAILED';

export const DELETE_TASK = 'DELETE_TASK';
export const DELETE_TASK_SUCCES = 'DELETE_TASK_SUCCES';
export const DELETE_TASK_FAILED = 'DELETE_TASK_FAILED';

