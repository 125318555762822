import * as logoutLMSConstants from '../constants/logoutLMS';

const initialState = {
  dataLogout: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case logoutLMSConstants.LOGOUT_LMS:
      return { ...state }

    case logoutLMSConstants.LOGOUT_LMS_SUCCESS:
      const { data } = action.payload;
      return {
        dataLogout: data,
        ...state
      }

    case logoutLMSConstants.LOGOUT_LMS_FAILED:
      const { error } = action.payload;
      console.log('logout', error);
      return {
        error,
        ...state
      }

    default:
      return state;
  }
}
export default reducer;