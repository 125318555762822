import * as recentStudentsConstants from '../constants/recentStudents'

export const recentStudents = (state) => {
  return {
    type: recentStudentsConstants.GET_RECENTSTUDENTS,
    payload: {
      state
    },
  }
}

export const recentStudentsSuccess = data => {
  return {
    type:recentStudentsConstants.GET_RECENTSTUDENTS_SUCCESS,
    payload: {
      data
    },
  }
}

export const recentStudentsFailed = error => {
  return {
    type: recentStudentsConstants.GET_RECENTSTUDENTS_FAILED,
    payload: {
      error
    },
  }
}