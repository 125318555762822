import * as accountsConstants from './../constants/accounts';

export const accounts = () => {
  return {
    type: accountsConstants.GET_ACCOUNTS,
  }
}

export const accountsSuccess = data => {
  return {
    type: accountsConstants.GET_ACCOUNTS_SUCCESS,
    payload: {
      data
    }
  }
}

export const accountsFailed = error => {
  return {
    type: accountsConstants.GET_ACCOUNTS_FAILED,
    payload: {
      error
    }
  }
}
