import axiosService from './../commons/axiosService';
import { API_ENDPOINT_LMS } from './../constants';

export const coursesSubmissionsAPI = (data) => {
  const token = JSON.parse(localStorage.getItem('checkLogin'));
  const url = `api/v1/courses/${data.id}/students/submissions?student_ids[]=all&workflow_state=unsubmitted`;

  const access_token = token ? token.access_token : null;

  const config = {
    headers: { Authorization: `Bearer ${access_token}` }
  };

  return axiosService.getConfig(`${API_ENDPOINT_LMS}/${url}`, data, config)
}
