import * as coursesConstants from '../constants/courses';

export const coursesOnly = (id, currentPage, keySearch) => {
  return {
    type: coursesConstants.GET_COURSES_ONLY,
    payload: {
      id,
      currentPage,
      keySearch,
    },
  }
}

export const coursesOnlySuccess = data => {
  return {
    type: coursesConstants.GET_COURSES_ONLY_SUCCESS,
    payload: {
      data
    }
  }
}

export const coursesOnlyFailed = error => {
  return {
    type: coursesConstants.GET_COURSES_ONLY_FAILED,
    payload: {
      error
    }
  }
}
