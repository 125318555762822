import React from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators }  from 'redux';
import { Modal, Button } from 'antd';
import './index.scss';

import * as modalActions from './../../actions/modal';

const ModalHera = (props) => {
  const { open, modalActionsCreators, component, title } = props;
  const { hideModal } = modalActionsCreators;
  return (
    <Modal
      title={title}
      visible={open}
      onCancel={hideModal}
      footer={[
        <Button type="primary" onClick={hideModal}>Cancel</Button>
      ]}
    >
      <div>{component}</div>
    </Modal>
  )
}

const mapStateToProps = state => ({
  open: state.modal.showModal,
  component: state.modal.component,
  title: state.modal.title,
})

const mapDispatchToProps = dispatch => ({
  modalActionsCreators: bindActionCreators(modalActions, dispatch),
})

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ModalHera)
