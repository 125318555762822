import * as accountTermsConstants from '../constants/accountTerms';

const initialState = {
  dataAccountTerms: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case accountTermsConstants.GET_ACCOUNTS_TERMS:
      return { ...state }
    case accountTermsConstants.GET_ACCOUNTS_TERMS_SUCCESS:
      const { data } = action.payload;
      return {
        ...state,
        dataAccountTerms: data,
      }
    case accountTermsConstants.GET_ACCOUNTS_TERMS_FAILED:
      const { error } = action.payload;
      console.log(error);
      return { ...state }

    default:
      return state;
  }
}

export default reducer;