/* eslint-disable no-mixed-operators */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import './index.scss';

import { TreeSelect, Table, Select, Button, Input, DatePicker } from 'antd';

import { DownloadOutlined } from '@ant-design/icons';
import { CSVLink } from "react-csv";

import * as subAccountsActions from './../../actions/subAccounts';
import * as coursesActions from './../../actions/courses';
import * as coursesPerPageAction from '../../actions/coursesPerPage';
import * as accountsActions from './../../actions/accounts';
import * as coursesSubmissionsAction from '../../actions/coursesSubmissions';

import { useHistory } from 'react-router';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Search } = Input;

const SubmissionAssignments = (props) => {

  var dataTable = [];

  const [valueSelect, setValueSelect] = useState(undefined);
  const [valueTreeSelect, setValueTreeSelect] = useState(undefined);
  const [change, setChange] = useState();
  const [current, setCurrent] = useState(1);
  const [search, setSearch] = useState();

  const {
    subAccountsActionCreators,
    coursesActionCreators,
    accountsActionCreators,
    coursesSubmissionsActionCreators,
    coursesPerPageActionCreators,
  } = props;

  const { subAccounts } = subAccountsActionCreators;
  const { courses } = coursesActionCreators;
  const { coursesPerPage } = coursesPerPageActionCreators
  const { accounts } = accountsActionCreators;
  const { coursesSubmissionsAction } = coursesSubmissionsActionCreators;
  const recursive = true;

  const columns = valueTreeSelect ? [
    { title: 'STT', dataIndex: 'stt', key: 'stt', },
    {
      title: 'Đơn vị', dataIndex: 'donvi', key: 'donvi',
      sorter: (a, b) => a.donvi.length - b.donvi.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Khoá học', dataIndex: 'khoahoc', key: 'khoahoc',
      sorter: (a, b) => a.khoahoc.length - b.khoahoc.length,
      sortDirections: ['descend', 'ascend'],
    },
    { title: 'Số lượng học viên', dataIndex: 'slhv', key: 'slhv' },
    { title: 'Số lượng không nộp', dataIndex: 'slkn', key: 'slkn' },
  ] :

    [
      { title: 'STT', dataIndex: 'stt', key: 'stt', width: '10%' },
      {
        title: 'Đơn vị', dataIndex: 'donvi', key: 'donvi', width: '10%'
      },
      {
        title: 'Khoá học', dataIndex: 'khoahoc', key: 'khoahoc', width: '10%'
      },
      { title: 'Số lượng học viên', dataIndex: 'slhv', key: 'slhv', width: '10%' },
      { title: 'Số lượng không nộp', dataIndex: 'slkn', key: 'slkn', width: '10%' },
    ];

  useEffect(() => {
    accounts();
  }, [accounts, recursive, subAccounts, coursesSubmissionsAction])

  const history = useHistory()

  // const access = JSON.parse(localStorage.getItem('checkLogin'));
  // if (access) {
  //   access.access_token = "Z3LD2tSGHMZbV1DmPQjUbDY7GayTGuAs6hUaF0aWMKcW93BEx1FgxVWcPwVSlgvR";
  //   localStorage.setItem("checkLogin", JSON.stringify(access));
  // }

  if (props.accounts.error === 'Request failed with status code 401') {
    alert('Phiên làm việc đã hết, vui lòng đăng nhập lại')
    localStorage.removeItem('checkLogin');
    history.push('/');
  }

  var dataOri = props.subAccounts.dataSubAccounts;

  const filteredArr = dataOri.reduce((acc, current) => {
    const x = acc.find(item => item.id === current.id);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);

  var newArray = filteredArr.filter(value => Object.keys(value).length !== 0);

  const newDataOri = newArray.map(({ id: value, name: title, ...rest }) => ({ value, title, ...rest }));

  const createTreeView = (newDataOri) => {
    var tree = [],
      object = {},
      parent,
      child;

    for (var i = 0; i < newDataOri.length; i++) {
      parent = newDataOri[i];

      object[parent.value] = parent;
      object[parent.value]["children"] = [];
    }

    for (var value in object) {
      if (object.hasOwnProperty(value)) {
        child = object[value];
        // i made some changes here incase some element is missing so you dont get error and just append th tree insetad
        if (child.parent_account_id && object[child["parent_account_id"]]) {
          object[child["parent_account_id"]]["children"].push(child);
        } else {
          tree.push(child);
        }
      }
    }
    return tree;
  }

  // here is how you build your UL treeview recursively
  var treeData = createTreeView(newDataOri);

  const onChangeSelect = (value) => {
    setValueSelect(value)
    subAccounts(value, recursive)
  };

  const onChangeTreeSelect = (value) => {
    courses(value, '1')
    coursesPerPage(value)
    setValueTreeSelect(value)
    setCurrent(1)
  };

  const dataCourses = props.courses.dataCourses;
  const dataCoursesPerPage = props.coursesPerPage.dataCoursesPerPage;
  const dataSubmissions = props.coursesSubmissions.dataCoursesSubmissions;

  const submissionsID = dataSubmissions.map(item => item.jsonDataCoursesSubmissions)

  const mapExport = new Map();

  const arrCoursesFilterWorkFlow = dataCourses.filter(value => value.workflow_state === 'available')

  const arrCoursesPerPageFilterWorkFlow = dataCoursesPerPage.filter(value => value.workflow_state)

  const filterWorkFlowDataCoursesPerPage = dataCoursesPerPage.filter(value => value.workflow_state === 'available')

  arrCoursesPerPageFilterWorkFlow.forEach(item => mapExport.set(item.id, item));

  if (valueTreeSelect) {
    if (submissionsID.length > 0) {
      dataSubmissions.map((item) => {
        arrCoursesFilterWorkFlow.map((itemCourses, index) => {
          if (item.jsonDataCoursesSubmissions.coursesID === itemCourses.id) {
            dataTable.push({
              key: index,
              stt: index + 1,
              donvi: itemCourses.account_name,
              khoahoc: itemCourses.name,
              slhv: itemCourses.total_students,
              slkn: item.jsonDataCoursesSubmissions.dataSubmissions.length,
              start_at: itemCourses.start_at,
              end_at: itemCourses.end_at,
            });
          }
        })
      })
    }
  }

  const onChange = (value, dateString) => {
    setChange(value)
  };

  if (change) {
    const dateChangeStart = new Date(change[0]._d);
    const dateChangeEnd = new Date(change[1]._d);
    let mdateChangeStart = new Date(dateChangeStart.getFullYear(), dateChangeStart.getMonth(), dateChangeStart.getDate());
    let mdateChangeEnd = new Date(dateChangeEnd.getFullYear(), dateChangeEnd.getMonth(), dateChangeEnd.getDate());

    let dataFilter = [];

    dataTable.map((item, index) => {
      const dateStart = item.start_at ? new Date(item.start_at) : null;
      const dateEnd = item.end_at ? new Date(item.end_at) : null;

      if ((dateStart <= mdateChangeStart || dateStart === null) && (mdateChangeEnd <= dateEnd || item.end_at === null)) {
        dataFilter.push({
          key: index,
          stt: index + 1,
          donvi: item.donvi,
          khoahoc: item.khoahoc,
          slhv: item.slhv,
          slkn: item.slkn,
        })
      }
    })
    dataTable = dataFilter;
  }

  const headers = [
    { label: "Đơn vị", key: "donvi" },
    { label: "Khóa học", key: "khoahoc" },
    { label: "Số lượng học viên", key: "slhv" },
    { label: "Số lượng không nộp", key: "slkn" },
  ];

  var today = new Date();
  var dateCSV = today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear();

  var dataExport = [];

  if (dataCoursesPerPage.length > 0 && dataSubmissions.length > 0) {
    dataSubmissions.map((item) => {
      dataCoursesPerPage.map((itemCourses, index) => {
        if (item.jsonDataCoursesSubmissions.coursesID === itemCourses.id && itemCourses.workflow_state === 'available') {
          dataExport.push({
            key: index,
            donvi: itemCourses.account_name,
            khoahoc: itemCourses.name,
            slhv: itemCourses.total_students,
            slkn: item.jsonDataCoursesSubmissions.dataSubmissions.length,
            start_at: itemCourses.start_at,
            end_at: itemCourses.end_at,
          });
        }
      })
    })
  }

  var checkExport = true

  if (dataExport.length > 0 && valueSelect && valueTreeSelect) {
    if (dataExport.length === filterWorkFlowDataCoursesPerPage.length) {
      checkExport = false
    }
  }

  if (search) {
    dataTable = dataTable.filter(item => {
      return item.khoahoc.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    });

    dataExport = dataExport.filter(item => {
      return item.khoahoc && item.khoahoc.toLowerCase().indexOf(search.toLowerCase()) !== -1 || item.tong || item.tltb;
    });
  }

  const handleSearch = (value) => {
    const checkSyntax = value.indexOf(";");
    if (checkSyntax === -1) {
      if (valueTreeSelect) {
        if (value.length > 3 || !value) {
          courses(valueTreeSelect, 1, value)
          coursesPerPage(valueTreeSelect, value)
          setCurrent(1);
        }
      }
    }
  }

  const handleFilter = keyword => {
    setSearch(keyword);
  };

  const onChangePaginate = (current) => {
    courses(valueTreeSelect, current.current)
  }

  const onChangePage = (page) => {
    setCurrent(page)
  }

  return (
    <>
      <div className="wrap__select">
        <div className="wrap__select-item">
          <div className="title">Đơn vị quản lý: </div>
          <Select
            className="basic select_item"
            showSearch
            placeholder="Chọn đơn vị quản lý"
            optionFilterProp="children"
            onChange={onChangeSelect}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {props.accounts.dataAccounts.map((acc, index) => {
              return (
                <Option key={index} value={acc.id}>{acc.name}</Option>
              )
            })}
          </Select>
        </div>

        <div className="wrap__select-item">
          <div className="title">Đơn vị trực thuộc: </div>
          <TreeSelect
            className="tree select_item"
            showSearch
            treeNodeFilterProp="title"
            value={valueTreeSelect}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            treeData={treeData}
            placeholder="Chọn đơn vị trực thuộc"
            onChange={onChangeTreeSelect}
          />
        </div>
      </div>

      <div className="wrap__select">
        <div className="wrap__select-item">
          <RangePicker onChange={onChange} className="select_item" />
        </div>

        <div className="wrap__select-item">
          <Search
            className="search select_item"
            placeholder="Tìm kiếm lớp học"
            onSearch={value => handleSearch(value)}
          />

          <Button icon={<DownloadOutlined style={{ color: '#1890ff' }} />} disabled={checkExport}>
            <CSVLink data={dataExport} headers={headers} filename={`TinhHinhNopBaiTap_${dateCSV}.csv`}> Xuất CSV</CSVLink>
          </Button>
        </div>
      </div>

      <Table columns={columns} dataSource={dataTable} scroll={{ x: 1300 }} scrollToFirstRowOnChange={false} onChange={onChangePaginate} pagination={{ total: [filterWorkFlowDataCoursesPerPage.length], current: current, onChange: onChangePage }} />
    </>
  )
}

const mapStateToProps = state => {
  return {
    subAccounts: state.subAccounts,
    courses: state.courses,
    coursesPerPage: state.coursesPerPageReducer,
    accounts: state.accounts,
    coursesSubmissions: state.coursesSubmissionsReducer,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    subAccountsActionCreators: bindActionCreators(subAccountsActions, dispatch),
    coursesActionCreators: bindActionCreators(coursesActions, dispatch),
    coursesPerPageActionCreators: bindActionCreators(coursesPerPageAction, dispatch),
    accountsActionCreators: bindActionCreators(accountsActions, dispatch),
    coursesSubmissionsActionCreators: bindActionCreators(coursesSubmissionsAction, dispatch),
  }
}

const widthConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(widthConnect)(SubmissionAssignments);