import * as coursesRSPerPageConstants from '../constants/coursesRecentStudentsPerPage';

const initialState = {
  dataCoursesRSPerPage: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case coursesRSPerPageConstants.GET_COURSERS_PERPAGE: {
      return {
        ...state,
      }
    }

    case coursesRSPerPageConstants.GET_COURSERS_PERPAGE_SUCCESS: {
      const { data } = action.payload;

      return {
        dataCoursesRSPerPage: data,
      }
    }

    case coursesRSPerPageConstants.GET_COURSERS_PERPAGE_FAILED: {
      const { error } = action.payload;
      console.log(error)
      return {
        ...state,
      }
    }

    default:
      return state;
  }
}
export default reducer;
