/* eslint-disable no-mixed-operators */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import './index.scss';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { TreeSelect, Table, Select, Input, Button, DatePicker } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { CSVLink } from "react-csv";
import moment from 'moment';

import * as subAccountsActions from './../../actions/subAccounts';
import * as coursesActions from './../../actions/courses';
import * as accountsActions from './../../actions/accounts';
import { useHistory } from 'react-router';
import * as coursesPerPageAction from '../../actions/coursesPerPage';
import * as coursesRecentStudentsPerPageAction from '../../actions/coursesRecentStudentsPerPage';

const { Option } = Select;

const { Search } = Input;

const { RangePicker } = DatePicker;

const dateFormat = 'YYYY-MM-DD';

const ReportCourses = (props) => {

  const [valueTreeSelect, setValueTreeSelect] = useState(undefined);
  const [valueSelect, setValueSelect] = useState(undefined);
  const [valueDate, setValueDate] = useState(undefined);
  const [checkDate, setCheckDate] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const { subAccountsActionCreators, coursesActionCreators, accountsActionCreators, coursesPerPageActionCreators, coursesRecentStudentsPerPageActionCreators } = props;
  const { subAccounts } = subAccountsActionCreators;
  const { courses } = coursesActionCreators;
  const { accounts } = accountsActionCreators;
  const { coursesPerPage } = coursesPerPageActionCreators

  const arrColumnCountRecentStudents = []

  const arrColumnCountRecentStudentsPerPage = []

  const columns = valueTreeSelect ? [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
    },
    {
      title: 'Đơn vị',
      dataIndex: 'donvi',
      key: 'donvi',
      sorter: (a, b) => a.donvi.length - b.donvi.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Khoá học',
      dataIndex: 'khoahoc',
      key: 'khoahoc',
      sorter: (a, b) => a.khoahoc.length - b.khoahoc.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Số lượngHV',
      dataIndex: 'slhv',
      key: 'slhv',
    },
    {
      title: 'Học viên truy cập',
      dataIndex: 'hvtc',
      key: 'hvtc'
    },
    {
      title: 'Tỉ lệ',
      dataIndex: 'tl',
      key: 'tl',
      render: (text, record, index) => {
        return (
          record.hvtc / record.slhv >= 0 ? `${Math.round((record.hvtc / record.slhv) * 100)}%` : 'N/A'
        )
      }
    }
  ] :

  [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
    },
    {
      title: 'Đơn vị',
      dataIndex: 'donvi',
      key: 'donvi',
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Khoá học',
      dataIndex: 'khoahoc',
      key: 'khoahoc',
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Số lượngHV',
      dataIndex: 'slhv',
      key: 'slhv',
    },
    {
      title: 'Học viên truy cập',
      dataIndex: 'hvtc',
      key: 'hvtc'
    },
    {
      title: 'Tỉ lệ',
      dataIndex: 'tl',
      key: 'tl',
      render: (text, record, index) => {
        return (
          record.hvtc / record.slhv >= 0 ? `${Math.round((record.hvtc / record.slhv) * 100)}%` : 'N/A'
        )
      }
    }
  ]

  for (var i = 0; i < props.recentStudents.dataRecentStudents.length; i++) {

    const newDataRecentStudents = props.recentStudents.dataRecentStudents[i].dataRecentStudent.filter(value => value.last_login !== null)

    const newArrFilterDate = valueDate && newDataRecentStudents.filter(value => {
      const monthRS = new Date(value.last_login).getUTCMonth() + 1
      const yearRS = new Date(value.last_login).getUTCFullYear();
      const dayRS = new Date(value.last_login).getUTCDate();

      const monthPick = new Date(valueDate).getUTCMonth() + 1
      const yearPick = new Date(valueDate).getUTCFullYear();
      const dayPick = new Date(valueDate).getUTCDate();

      const newdateRecentStudents = yearRS + "/" + monthRS + "/" + dayRS;
      const newdatePick = yearPick + "/" + monthPick + "/" + dayPick;

      return new Date(newdateRecentStudents) >= new Date(newdatePick)
    }
    )

    const newJson = {
      id: props.recentStudents.dataRecentStudents[i].idCourses,
      countRecentStudents: checkDate ? newArrFilterDate.length : newDataRecentStudents.length
    }

    arrColumnCountRecentStudents.push(newJson)
  }

  for (var j = 0; j < props.recentStudentsPerPage.dataCoursesRSPerPage.length; j++) {

    const newDataRecentStudentsPerPage = props.recentStudentsPerPage.dataCoursesRSPerPage[j].dataRecentStudent.filter(value => value.last_login !== null)

    const newArrFilterDate = valueDate && newDataRecentStudentsPerPage.filter(value => {
      const monthRS = new Date(value.last_login).getUTCMonth() + 1
      const yearRS = new Date(value.last_login).getUTCFullYear();
      const dayRS = new Date(value.last_login).getUTCDate();

      const monthPick = new Date(valueDate).getUTCMonth() + 1
      const yearPick = new Date(valueDate).getUTCFullYear();
      const dayPick = new Date(valueDate).getUTCDate();

      const newdateRecentStudents = yearRS + "/" + monthRS + "/" + dayRS;
      const newdatePick = yearPick + "/" + monthPick + "/" + dayPick;

      return new Date(newdateRecentStudents) >= new Date(newdatePick)
    }
    )

    const newJson = {
      id: props.recentStudentsPerPage.dataCoursesRSPerPage[j],
      countRecentStudents: checkDate ? newArrFilterDate.length : newDataRecentStudentsPerPage.length
    }

    arrColumnCountRecentStudentsPerPage.push(newJson)
  }

  const recursive = true;

  var today = new Date();
  var dateCSV = today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear();

  const onChangeTreeSelect = value => {
    courses(value, 1)
    setValueTreeSelect(value)
    coursesPerPage(value)
    setCurrentPage(1);
  };

  const onChangeSelect = (value) => {
    setValueSelect(value)
    subAccounts(value, recursive)
  }

  const handleSearch = (value) => {
    const checkSyntax = value.indexOf(";");
    if (checkSyntax === -1) {
      if (valueTreeSelect) {
        if (value.length > 3 || !value) {
          courses(valueTreeSelect, 1, value)
          coursesPerPage(valueTreeSelect, value)
          setCurrentPage(1);
        }
      }
    }
  }

  const handleChangeData = (value) => {
    if (value[0]) {
      setValueDate(value[0]._d)
      setCheckDate(true)
    } else {
      setCheckDate(false)
      setValueDate('')
    }
  }

  const onChangePaginate = (current) => {
    courses(valueTreeSelect, current.current)
  }

  const onChanageCurrentPage = (current) => {
    setCurrentPage(current)
  }

  useEffect(() => {
    accounts()
  }, [accounts, recursive, subAccounts])

  const headers = [
    { label: "Đơn vị", key: "donvi" },
    { label: "Khóa học", key: "khoahoc" },
    { label: "Số lượngHV", key: "slhv" },
    { label: "Số lượng học viên truy cập", key: "hvtc" },
    { label: "Tỉ lệ", key: "tl" },
  ];

  var dataCSV = [];

  var dataOri = props.subAccounts.dataSubAccounts;

  const filteredArr = dataOri.reduce((acc, current) => {
    const x = acc.find(item => item.id === current.id);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);

  var newArray = filteredArr.filter(value => Object.keys(value).length !== 0);

  const newDataOri = newArray.map(({ id: value, name: title, ...rest }) => ({ value, title, ...rest }));

  const createTreeView = (newDataOri) => {
    var tree = [],
      object = {},
      parent,
      child;

    for (var i = 0; i < newDataOri.length; i++) {
      parent = newDataOri[i];

      object[parent.value] = parent;
      object[parent.value]["children"] = [];
    }

    for (var value in object) {
      if (object.hasOwnProperty(value)) {
        child = object[value];
        // i made some changes here incase some element is missing so you dont get error and just append th tree insetad
        if (child.parent_account_id && object[child["parent_account_id"]]) {
          object[child["parent_account_id"]]["children"].push(child);
        } else {
          tree.push(child);
        }
      }
    }
    return tree;
  }

  // here is how you build your UL treeview recursively
  var treeData = createTreeView(newDataOri);

  //var mixArray = props.courses.dataCourses.map((item, i) => Object.assign({}, item, arrColumnCountRecentStudents[i]));

  const map = new Map();
  const mapExport = new Map();

  const arrCoursesFilterWorkFlow = props.courses.dataCourses.filter(value => value.workflow_state === 'available')
  //const arrCoursesFilterWorkFlow = props.courses.dataCourses.filter(value => value.workflow_state)

  //const arrCoursesPerPageFilterWorkFlow = props.coursesPerPage.dataCoursesPerPage.filter(value => value.workflow_state)
  const arrCoursesPerPageFilterWorkFlow = props.coursesPerPage.dataCoursesPerPage.filter(value => value.workflow_state === 'available')

  const filterWorkFlowDataCoursesPerPage = props.coursesPerPage.dataCoursesPerPage.filter(value => value.workflow_state === 'available')
  //const filterWorkFlowDataCoursesPerPage = props.coursesPerPage.dataCoursesPerPage.filter(value => value.workflow_state)

  arrCoursesFilterWorkFlow.forEach(item => map.set(item.id, item));
  arrColumnCountRecentStudents.forEach(item => map.set(item.id, { ...map.get(item.id), ...item }));

  arrCoursesPerPageFilterWorkFlow.forEach(item => mapExport.set(item.id, item));
  arrColumnCountRecentStudentsPerPage.forEach(item => mapExport.set(item.id.idCourses, { ...mapExport.get(item.id.idCourses), ...item }));

  const mixArray = Array.from(map.values());

  const mixArrayExport = Array.from(mapExport.values());

  const dataTable = valueTreeSelect && mixArray[0] && mixArray[0].name ? mixArray.map((c, index) => {
    return ({
      stt: index + 1,
      donvi: c.account_name,
      khoahoc: c.name,
      slhv: c.total_students,
      hvtc: c.countRecentStudents
    })
  })

    : []

  for (var c = 0; c < mixArrayExport.length; c++) {
    dataCSV.push({
      donvi: mixArrayExport[c].account_name,
      khoahoc: mixArrayExport[c].name,
      slhv: mixArrayExport[c].total_students,
      hvtc: mixArrayExport[c].countRecentStudents,
      tl: isNaN(mixArrayExport[c].countRecentStudents / mixArrayExport[c].total_students) ? 'N/A' : Math.round((mixArrayExport[c].countRecentStudents / mixArrayExport[c].total_students) * 100) + '%'
    })
  }

  var filteredArrTable = dataTable.filter(value => value.donvi !== undefined)

  const checkRSArrTable = props.recentStudents.dataRecentStudents.length > 0 && props.recentStudents.dataRecentStudents.length === props.courses.dataCourses.length ? filteredArrTable : [];

  const filteredArrTableCountPercent = filteredArrTable.filter(value => value.slhv > 0)

  var arrCountPercentStudents = filteredArrTableCountPercent.map(item => item.hvtc / item.slhv)

  var countStudents = arrCountPercentStudents.reduce((accum, item) => accum + item, 0)

  const countPercentStudents = Math.round(countStudents * 100 / arrCountPercentStudents.length)

  const paginationCount = filterWorkFlowDataCoursesPerPage;

  const filteredArrTableCountPercentExport = mixArrayExport.filter(value => value.total_students > 0)

  const filteredSearchArrTableCountPercentExport = filteredArrTableCountPercentExport

  var arrCountPercentStudentsExport = filteredSearchArrTableCountPercentExport.map(item => item.countRecentStudents / item.total_students)

  var countStudentsExport = arrCountPercentStudentsExport.reduce((accum, item) => accum + item, 0)

  const countPercentStudentsExport = Math.round(countStudentsExport * 100 / arrCountPercentStudentsExport.length)

  const countTotalStudents = valueTreeSelect ? filteredSearchArrTableCountPercentExport.reduce((accum, item) => accum + item.total_students, 0) : 0;

  var dateExport = valueDate ? JSON.stringify(valueDate).slice(1, 11) : '';

  if (valueSelect && valueTreeSelect) {
    dataCSV.push({
      donvi: `Ngày truy cập gần nhất: ${dateExport}`,
      slhv: `Tổng số học viên: ${countTotalStudents}`,
      tl: `Tỉ lệ trung bình: ${isNaN(countPercentStudentsExport) ? 0 : countPercentStudentsExport + '%'}`,
    })
  }

  const filteredDataCSV = dataCSV.filter(value => value.donvi !== undefined || value.tong || value.tltb || value.ncdn === '' || value.ncdn)

  // Err 401
  const history = useHistory();

  if (props.accounts.error === 'Request failed with status code 401') {
    alert('Phiên làm việc đã hết, vui lòng đăng nhập lại')
    localStorage.removeItem('checkLogin');
    history.push('/');
  }
  // End Err 401

  var checkExprot = true

  checkExprot = isNaN(countPercentStudentsExport) ? true : false;

  return (
    <React.Fragment>
      <div className="wrap__select">
        <div className="wrap__select-item">
          <div className="title">Đơn vị quản lý: </div>
          <Select
            className="basic select_item"
            showSearch
            placeholder="Chọn đơn vị quản lý"
            optionFilterProp="children"
            onChange={onChangeSelect}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {props.accounts.dataAccounts.map((acc, index) => {
              return (
                <Option value={acc.id} key={index}>{acc.name}</Option>
              )
            })}
          </Select>
        </div>

        <div className="wrap__select-item">
          <div className="title">Đơn vị trực thuộc: </div>
          <TreeSelect
            className="tree select_item"
            showSearch
            treeNodeFilterProp="title"
            value={valueTreeSelect}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            treeData={treeData}
            placeholder="Chọn đơn vị trực thuộc"
            onChange={onChangeTreeSelect}
          />
        </div>
      </div>

      <div className="wrap__select">
        <div className="wrap__select-item">
          <RangePicker
            defaultValue={['', moment(today, dateFormat)]}
            placeholder={['Ngày đăng nhập cuối cùng', '']}
            disabled={[false, true]}
            onChange={handleChangeData}
            className="select_item"
          />
        </div>

        <div className="wrap__select-item">
          <Search
            className="search select_item"
            placeholder="Tìm kiếm lớp học"
            //onSearch={value => handleFilter(value)}
            onSearch={value => handleSearch(value)}
          />

          <Button icon={<DownloadOutlined style={{ color: '#1890ff' }} />} disabled={checkExprot} >
            <CSVLink data={filteredDataCSV} headers={headers} filename={`SoNguoiHoc_${dateCSV}.csv`}> Xuất CSV</CSVLink>
          </Button>
        </div>
      </div>

      <Table columns={columns} dataSource={checkRSArrTable} scroll={{ x: 1300 }} onChange={onChangePaginate} pagination={{ total: [filterWorkFlowDataCoursesPerPage.length], current: currentPage, onChange: onChanageCurrentPage }} />

      <div className="count">Tổng số người học: {countTotalStudents}</div>
      <div className="count">Tỷ lệ trung bình: { !valueTreeSelect || isNaN(countPercentStudentsExport) ? 0 : countPercentStudentsExport}%</div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    subAccounts: state.subAccounts,
    courses: state.courses,
    accounts: state.accounts,
    coursesPerPage: state.coursesPerPage,
    recentStudents: state.recentStudents,
    recentStudentsPerPage: state.recentStudentsPerPage,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    subAccountsActionCreators: bindActionCreators(subAccountsActions, dispatch),
    coursesActionCreators: bindActionCreators(coursesActions, dispatch),
    coursesPerPageActionCreators: bindActionCreators(coursesPerPageAction, dispatch),
    accountsActionCreators: bindActionCreators(accountsActions, dispatch),
    coursesRecentStudentsPerPageActionCreators: bindActionCreators(coursesRecentStudentsPerPageAction, dispatch),
  }
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ReportCourses)
