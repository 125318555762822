import * as conferencesConstants from '../constants/reportConferences'

export const conferences = (state) => {
  return {
    type: conferencesConstants.GET_CONFERENCES,
    payload: {
      state
    },
  }
}

export const conferencesSuccess = data => {
  return {
    type:conferencesConstants.GET_CONFERENCES_SUCCESS,
    payload: {
      data
    },
  }
}

export const conferencesFailed = error => {
  return {
    type: conferencesConstants.GET_CONFERENCES_FAILED,
    payload: {
      error
    },
  }
}