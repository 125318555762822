import React, { useEffect, useState } from 'react';
import './index.scss';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { TreeSelect, Table, Select, Input, Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { CSVLink } from "react-csv";

import * as subAccountsActions from './../../actions/subAccounts';
import * as coursesActions from './../../actions/coursesOnly';
import * as accountsActions from './../../actions/accounts';
import { useHistory } from 'react-router';
import * as coursesPerPageAction from '../../actions/coursesPerPageOnly';

const { Option } = Select;

const { Search } = Input;

const ReportCountStudents = (props) => {

  const [valueTreeSelect, setValueTreeSelect] = useState(undefined);
;
  const [currentPage, setCurrentPage] = useState(1);

  const { subAccountsActionCreators, coursesActionCreators, accountsActionCreators, coursesPerPageActionCreators } = props;
  const { subAccounts } = subAccountsActionCreators;
  const { coursesOnly } = coursesActionCreators;
  const { accounts } = accountsActionCreators;
  const { coursesOnlyPerPage } = coursesPerPageActionCreators;

  // Err 401
  const history = useHistory();

  if (props.accounts.error === 'Request failed with status code 401') {
    alert('Phiên làm việc đã hết, vui lòng đăng nhập lại')
    localStorage.removeItem('checkLogin');
    history.push('/');
  }
  // End Err 401

  const recursive = true;

  var today = new Date();
  var dateCSV = today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear();

  const onChangeTreeSelect = value => {
    coursesOnly(value, 1)
    setValueTreeSelect(value)
    coursesOnlyPerPage(value)
    setCurrentPage(1);
  };

  const onChangeSelect = (value) => {
    subAccounts(value, recursive)
  }

  const handleSearch = (value) => {
    const checkSyntax = value.indexOf(";");
    if (checkSyntax === -1) {
      if (valueTreeSelect) {
        if (value.length > 3 || !value) {
          coursesOnly(valueTreeSelect, 1, value)
          coursesOnlyPerPage(valueTreeSelect, value)
          setCurrentPage(1);
        }
      }
    }
  }

  useEffect(() => {
    accounts()
  }, [accounts, recursive, subAccounts])

  const onChangePaginate = (current) => {
    coursesOnly(valueTreeSelect, current.current)
  }

  const onChanageCurrentPage = (current) => {
    setCurrentPage(current)
  }

  const columns = valueTreeSelect ? [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
    },
    {
      title: 'Đơn vị',
      dataIndex: 'donvi',
      key: 'donvi',
      sorter: (a, b) => a.donvi.length - b.donvi.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Khoá học',
      dataIndex: 'khoahoc',
      key: 'khoahoc',
      sorter: (a, b) => a.khoahoc.length - b.khoahoc.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Số lượngHV',
      dataIndex: 'slhv',
      key: 'slhv',
    }
  ] :

  [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
    },
    {
      title: 'Đơn vị',
      dataIndex: 'donvi',
      key: 'donvi',
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Khoá học',
      dataIndex: 'khoahoc',
      key: 'khoahoc',
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Số lượngHV',
      dataIndex: 'slhv',
      key: 'slhv',
    }
  ]

  var dataOri = props.subAccounts.dataSubAccounts;

  const filteredArr = dataOri.reduce((acc, current) => {
    const x = acc.find(item => item.id === current.id);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);

  var newArray = filteredArr.filter(value => Object.keys(value).length !== 0);

  const newDataOri = newArray.map(({ id: value, name: title, ...rest }) => ({ value, title, ...rest }));

  const createTreeView = (newDataOri) => {
    var tree = [],
      object = {},
      parent,
      child;

    for (var i = 0; i < newDataOri.length; i++) {
      parent = newDataOri[i];

      object[parent.value] = parent;
      object[parent.value]["children"] = [];
    }

    for (var value in object) {
      if (object.hasOwnProperty(value)) {
        child = object[value];
        // i made some changes here incase some element is missing so you dont get error and just append th tree insetad
        if (child.parent_account_id && object[child["parent_account_id"]]) {
          object[child["parent_account_id"]]["children"].push(child);
        } else {
          tree.push(child);
        }
      }
    }
    return tree;
  }

  // here is how you build your UL treeview recursively
  var treeData = createTreeView(newDataOri);

  const arrCoursesFilterWorkFlow =  props.coursesOnly.dataCoursesOnly.filter(value => value.workflow_state === 'available')

  const filterWorkFlowDataCoursesPerPage = props.coursesPerPageOnly.dataCoursesPerPageOnly.filter(value => value.workflow_state === 'available')

  const countTotalStudents = valueTreeSelect ? filterWorkFlowDataCoursesPerPage.reduce((accum, item) => accum + item.total_students, 0) : 0;

  const dataTable = valueTreeSelect ? arrCoursesFilterWorkFlow.map((c, index) => {
    return ({
      stt: index + 1,
      donvi: c.account_name,
      khoahoc: c.name,
      slhv: c.total_students,
      hvtc: c.countRecentStudents
    }) 
  }) : []

  const headers = [
    { label: "Đơn vị", key: "donvi" },
    { label: "Khóa học", key: "khoahoc" },
    { label: "Số lượngHV", key: "slhv" },
  ];

  var dataCSV = [];

  for (var c = 0; c < arrCoursesFilterWorkFlow.length; c++) {
    dataCSV.push({
      donvi: arrCoursesFilterWorkFlow[c].account_name,
      khoahoc: arrCoursesFilterWorkFlow[c].name,
      slhv: arrCoursesFilterWorkFlow[c].total_students,
    })
  }

  if (valueTreeSelect) {
    dataCSV.push({
      slhv: `Tổng số học sinh: ${countTotalStudents}`
    })
  }


  return (
    <React.Fragment>
      <div className="wrap__select">
        <div className="wrap__select-item">
          <div className="title">Đơn vị quản lý: </div>
          <Select
            className="basic select_item"
            showSearch
            placeholder="Chọn đơn vị quản lý"
            optionFilterProp="children"
            onChange={onChangeSelect}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {props.accounts.dataAccounts.map((acc, index) => {
              return (
                <Option value={acc.id} key={index}>{acc.name}</Option>
              )
            })}
          </Select>
        </div>

        <div className="wrap__select-item">
          <div className="title">Đơn vị trực thuộc: </div>
          <TreeSelect
            className="tree select_item"
            showSearch
            treeNodeFilterProp="title"
            value={valueTreeSelect}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            treeData={treeData}
            placeholder="Chọn đơn vị trực thuộc"
            onChange={onChangeTreeSelect}
          />
        </div>
      </div>

      <div className="wrap__select">

        <div className="wrap__select-item">

        </div>

        <div className="wrap__select-item">
          <Search
            className="search select_item"
            placeholder="Tìm kiếm lớp học"
            //onSearch={value => handleFilter(value)}
            onSearch={value => handleSearch(value)}
          />

          <Button icon={<DownloadOutlined style={{ color: '#1890ff' }} />}>
            <CSVLink data={dataCSV} headers={headers} filename={`SoNguoiHoc_${dateCSV}.csv`}> Xuất CSV</CSVLink>
          </Button>
        </div>
      </div>

      <Table columns={columns} dataSource={dataTable} scroll={{ x: 1300 }} onChange={onChangePaginate} pagination={{ total: [filterWorkFlowDataCoursesPerPage ? filterWorkFlowDataCoursesPerPage.length : 0], current: currentPage, onChange: onChanageCurrentPage }} />

      <div className="count">Tổng số người học: {countTotalStudents}</div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  console.log('state', state)
  return {
    subAccounts: state.subAccounts,
    coursesOnly: state.coursesOnly,
    accounts: state.accounts,
    coursesPerPageOnly: state.coursesPerPageOnly,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    subAccountsActionCreators: bindActionCreators(subAccountsActions, dispatch),
    coursesActionCreators: bindActionCreators(coursesActions, dispatch),
    coursesPerPageActionCreators: bindActionCreators(coursesPerPageAction, dispatch),
    accountsActionCreators: bindActionCreators(accountsActions, dispatch),
  }
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ReportCountStudents)
