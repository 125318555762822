import AdminHomePage from '../containers/AdminHomePage';
// import TaskBoard from '../containers/TaskBoard';
import ReportConferences from '../containers/ReportConferences';
import ReportCourses from '../containers/ReportCourses';
import LoginPage from '../containers/LoginPage';
// Submission Assignments
import SubmissionAssignments from '../containers/SubmissionAssignments';
import ReportCountStudents from '../containers/ReportCountStudents';

export const API_ENDPOINT = 'http://localhost:8000';

export const API_ENDPOINT_LMS = 'https://lms.herapo.net';

export const STATUSES = [
  {
    value: 0,
    label: "READY"
  },
  {
    value: 1,
    label: "IN PROGRESS"
  },
  {
    value: 2,
    label: "COMPLETED"
  }
];

export const STATUS_CODE = {
  SUCCESS: 200,
  CREATED: 201,
  UPDATED: 202,
};

export const ADMIN_ROUTES = [
  {
    key: '/admin',
    path: '/admin',
    exact: true,
    name: 'Trang quản trị',
    component: AdminHomePage,
  },
  // {
  //   key: '/admin/task-board',
  //   path: '/admin/task-board',
  //   name: 'Manager Task',
  //   component: TaskBoard,
  // },
  {
    key: '/admin/report-conferences',
    path: '/admin/report-conferences',
    name: 'Lớp trực tuyến đang diễn ra',
    component: ReportConferences
  },
  {
    key: '/admin/report-countstudents',
    path: '/admin/report-countstudents',
    name: 'Số người học',
    component: ReportCountStudents
  },
  {
    key: '/admin/report-courses',
    path: '/admin/report-courses',
    name: 'Số người học theo đơn vị trực thuộc',
    component: ReportCourses
  },
  {
    key: '/admin/report-submission-assignments',
    path: '/admin/report-submission-assignments',
    name: 'Tình hình học viên không nộp bài',
    component: SubmissionAssignments
  },
]

export const ROUTES = [
  {
    key: '/',
    name: 'Login',
    path: '/',
    component: LoginPage,
  }
]

