import * as accountTermsConstants from '../constants/accountTerms';

export const accountTermsAction = (id) => {
  return {
    type: accountTermsConstants.GET_ACCOUNTS_TERMS,
    payload: {
      id,
    }
  }
};

export const accountTermsActionSuccess = (data) => {
  return {
    type: accountTermsConstants.GET_ACCOUNTS_TERMS_SUCCESS,
    payload: { data }
  }
};

export const accountTermsActionFailed = (error) => {
  return {
    type: accountTermsConstants.GET_ACCOUNTS_TERMS_FAILED,
    payload: { error }
  }
}