/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { NavLink, useHistory } from 'react-router-dom';
import { Table, Button } from 'antd';
import { DatePicker } from 'antd';
import SearchTable from './SearchTable';
import { DownloadOutlined } from '@ant-design/icons';
import { CSVLink } from "react-csv";
import './index.scss';
import * as conferencesActions from './../../actions/reportConferences';
import * as coursesAction from './../../actions/coursesConferences';

const ReportConferences = (props) => {

  const history = useHistory();

  if (props.conferences.error === 'Request failed with status code 401' && props.courses.error === 'Request failed with status code 401') {
    alert('Phiên làm việc đã hết, vui lòng đăng nhập lại')
    localStorage.removeItem('checkLogin');
    history.push('/');
  }

  const columns = [
    {
      title: 'Mã lớp', dataIndex: 'id', key: 'id',
      sorter: (a, b) => a.id - b.id,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Tên lớp', dataIndex: 'name', key: 'name', render: text => <NavLink to="">{text}</NavLink>, width: '25%',
      sorter: (a, b) => a.name.length - b.name.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Khóa học', dataIndex: 'course', key: 'course',
      sorter: (a, b) => a.course.length - b.course.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Đơn vị', dataIndex: 'account', key: 'account',
      sorter: (a, b) => a.account.length - b.account.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Bắt đầu', dataIndex: 'started_at', key: 'started_at',
      sorter: (a, b) => a.started_at.length - b.started_at.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Kết thúc', dataIndex: 'ended_at', key: 'ended_at',
      sorter: (a, b) => a.ended_at.length - b.ended_at.length,
      sortDirections: ['descend', 'ascend'],
    },
  ];

  let dataTable = [];
  let dataOriginal = [];

  const headers = [
    { label: "Mã lớp", key: "id" },
    { label: "Tên lớp", key: "name" },
    { label: "Khóa học", key: "course" },
    { label: "Đơn vị", key: "account" },
    { label: "Bắt đầu", key: "started_at" },
    { label: "Kết thúc", key: "ended_at" },
  ];

  var today = new Date();
  var dateCSV = today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear();

  const stateURL = ``;
  const coursesURL = `account`;

  const { conferencesActionCreators, coursesActionCreator } = props;
  const { conferences } = conferencesActionCreators;
  const { coursesAction } = coursesActionCreator;

  useEffect(() => {
    conferences(stateURL);
    coursesAction(coursesURL)
  }, [conferences, coursesAction, coursesURL, stateURL])

  const dataConferences = props.conferences.dataConferences.conferences;
  const dataCourses = props.courses.dataCourses;

  if (dataConferences) {
    dataConferences.map(item => {
      const dateStart = new Date(item.started_at)
      const dateEnd = new Date(item.ended_at)
      const dateTimeStart = dateStart.getFullYear() + "-" + (dateStart.getMonth() + 1) + "-" + (dateStart.getDate() + " " + dateStart.getHours() + ":" + dateStart.getSeconds());
      const dateTimeEnd = dateEnd.getFullYear() + "-" + (dateEnd.getMonth() + 1) + "-" + (dateEnd.getDate()) + " " + dateEnd.getHours() + ":" + dateEnd.getSeconds();
      dataCourses.map((itemCourses, key) => {
        if (item.context_id === itemCourses.id) {
          dataTable.push({
            key: item.id,
            id: item.id,
            name: item.title,
            course: itemCourses.name,
            account: itemCourses.account.name,
            started_at: item.started_at ? dateTimeStart : '',
            ended_at: item.ended_at ? dateTimeEnd : '',
          })
          dataOriginal.push({
            key: item.id,
            id: item.id,
            name: item.title,
            course: itemCourses.name,
            account: itemCourses.account.name,
            started_at: item.started_at ? dateTimeStart : '',
            ended_at: item.ended_at ? dateTimeEnd : '',
          })
        }
      })
    })
  };

  const [change, setChange] = useState();

  const onChange = (date, dateString) => {
    setChange(date)
  };

  if (change) {
    const dateChange = new Date(change._d);
    var dataFilter = [];
    dataOriginal.map((item, index) => {
      const dateStart = new Date(item.started_at);
      const dateEnd = new Date(item.ended_at);

      let mdateChange = new Date(dateChange.getFullYear(), dateChange.getMonth(), dateChange.getDate(), dateChange.getHours());
      let start = new Date(dateStart.getFullYear(), dateStart.getMonth(), dateStart.getDate(), dateStart.getHours());
      let end = new Date(dateEnd.getFullYear(), dateEnd.getMonth(), dateEnd.getDate(), dateEnd.getHours());

      if (start <= mdateChange && (mdateChange <= end || item.ended_at === "")) {
        dataFilter.push({
          key: index,
          id: item.id,
          name: item.name,
          course: item.course,
          account: item.account,
          started_at: item.started_at,
          ended_at: item.ended_at,
        })
      }
    })
    dataTable = dataFilter;
  }

  const onChangeTable = (sorter, extra) => {
  }

  const [search, setSearch] = useState()

  const handleFilter = keyword => {
    setSearch(keyword);
  };

  if (search) {
    dataTable = dataTable.filter(item => {
      return item.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    });
  }

  return (
    <>
      <div className="wrap__select">
        <div className="wrap__select-item">
          <div className="title">Chọn ngày giờ </div>
          <DatePicker showTime={{ format: 'HH' }} format="YYYY-MM-DD HH:00" onChange={onChange} className="select_item" placeholder="Chọn ngày và giờ" />
        </div>
        <div className="wrap__select-item">
          <SearchTable onFilter={handleFilter} />
        </div>
        <div className="wrap__select-item">
          <Button icon={<DownloadOutlined style={{ color: '#1890ff' }} />}>
            <CSVLink data={dataOriginal} headers={headers} filename={`LopTrucTuyen_${dateCSV}.csv`}> Xuất CSV</CSVLink>
          </Button>
        </div>
      </div>
      <Table columns={columns} dataSource={dataTable} onChange={onChangeTable} scroll={{ x: 1300 }} />
    </>
  )
}

const mapStateToProps = state => {
  return {
    conferences: state.reportConferences,
    courses: state.coursesConferences,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    conferencesActionCreators: bindActionCreators(conferencesActions, dispatch),
    coursesActionCreator: bindActionCreators(coursesAction, dispatch),
  }
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ReportConferences)
