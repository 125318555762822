import * as coursesConstants from '../constants/coureseConferences';

export const coursesAction = (include) => {
  return {
    type: coursesConstants.GET_COURSES_CONFERENCES,
    payload: {
      include
    }
  }
}

export const coursesActionSuccess = (data) => {
  return {
    type: coursesConstants.GET_COURSES_CONFERENCES_SUCCESS,
    payload: {
      data
    }
  }
}

export const coursesActionFailed = (error) => {
  return {
    type: coursesConstants.GET_COURSES_CONFERENCES_FAILED,
    payload: {
      error
    }
  }
}