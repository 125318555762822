import * as coursesPerPageConstants from '../constants/coursesPerPage';

export const coursesOnlyPerPage = (id, keySearch) => {
  return {
    type: coursesPerPageConstants.GET_COURSES_PERPAGE_ONLY,
    payload: {
      id,
      keySearch
    },
  }
}

export const coursesOnlyPerPageSuccess = data => {
  return {
    type: coursesPerPageConstants.GET_COURSES_PERPAGE_ONLY_SUCCESS,
    payload: {
      data
    }
  }
}

export const coursesOnlyPerPageFailed = error => {
  return {
    type: coursesPerPageConstants.GET_COURSES_PERPAGE_ONLY_FAILED,
    payload: {
      error
    }
  }
}
