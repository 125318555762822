import axiosService from './../commons/axiosService';
import { API_ENDPOINT_LMS } from './../constants';

export const subAccounts = (data) => {
  const token = JSON.parse(localStorage.getItem('checkLogin'));
  const url = `api/v1/accounts/${data.id}/sub_accounts?recursive=${data.recursive}`;

  const access_token = token ? token.access_token : null;

  const config = {
      headers: { Authorization: `Bearer ${access_token}` }
  };

  return axiosService.getConfig(`${API_ENDPOINT_LMS}/${url}`, data, config)
}
