import * as logoutLMSConstants from '../constants/logoutLMS';

export const logoutLMSAction = (expire_sessions) => {
  return {
    type: logoutLMSConstants.LOGOUT_LMS,
    payload: {
      expire_sessions
    }
  }
}

export const logoutLMSSuccess = (data) => {
  return {
    type: logoutLMSConstants.LOGOUT_LMS_SUCCESS,
    payload: {
      data
    }
  }
}

export const logoutLMSFailed = (error) => {
  return {
    type: logoutLMSConstants.LOGOUT_LMS_FAILED,
    payload: {
      error
    }
  }
}