import React from 'react';
import './index.scss'
import img from '../../images/herapo-lms-logo.png';

const AdminHomePage = (props) => {

  return (
    <>
      <div className="admin__img" >
        <img src={img} alt="herapo-login" width='100%' />
      </div>
    </>
  )
}

export default AdminHomePage;
