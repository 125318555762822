import * as recentStudents from '../constants/recentStudents';

const initialState = {
  dataRecentStudents: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case recentStudents.GET_RECENTSTUDENTS: {
      return {
        ...state,
      }
    }

    case recentStudents.GET_RECENTSTUDENTS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        dataRecentStudents: data,
      }
    }

    case recentStudents.GET_RECENTSTUDENTS_FAILED: {
      const { error } = action.payload;
      console.log(error)
      return {
        ...state,
      }
    }

    default:
      return state;
  }
}
export default reducer;
