import * as loginLMSConstants from './../constants/loginLMS';

const initialState = {
  dataLogin: [],
  isLogin: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case loginLMSConstants.LOGIN_LMS: {
      return {
        ...state,
      }
    }

    case loginLMSConstants.LOGIN_LMS_SUCCESS: {
      const { data } = action.payload;
      localStorage.setItem("checkLogin", JSON.stringify(data));
      return {
        ...state,
        dataLogin: data,
        isLogin: true
      }
    }

    case loginLMSConstants.LOGIN_LMS_FAILED: {
      const { error } = action.payload;
      console.log(error)
      return {
        ...state,
      }
    }

    default:
      return state;
  }
}
export default reducer;
