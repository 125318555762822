import * as subAccountsConstants from './../constants/subAccounts';

export const subAccounts = (id, recursive) => {
  return {
    type: subAccountsConstants.GET_SUBACCOUNTS,
    payload: {
      id,
      recursive,
    },
  }
}

export const subAccountsSuccess = data => {
  return {
    type: subAccountsConstants.GET_SUBACCOUNTS_SUCCESS,
    payload: {
      data
    }
  }
}

export const subAccountsFailed = error => {
  return {
    type: subAccountsConstants.GET_SUBACCOUNTS_FAILED,
    payload: {
      error
    }
  }
}
