import axiosService from '../commons/axiosService';
import { API_ENDPOINT_LMS } from '../constants/index';

export const logoutLMSAPI = (data) => {

  const url = `login/oauth2/token?expire_sessions=${data.expire_sessions}`;
  const token = JSON.parse(localStorage.getItem("checkLogin"));
  const access_token = token ? token.access_token : null

  const config = {
    headers: { Authorization: `Bearer ${access_token}` }
  };

  return axiosService.deleteConfig(`${API_ENDPOINT_LMS}/${url}`, data, config)
}