import React from 'react';
import './index.scss';
import { PageHeader, Button, Dropdown, Menu } from 'antd';
import { UserAddOutlined, LogoutOutlined } from '@ant-design/icons';
// import { withRouter } from "react-router";
import { connect } from 'react-redux';
import * as logoutLMSAction from '../../../actions/logoutLMS';
import { bindActionCreators, compose } from 'redux';
import { useHistory } from 'react-router';

const Header = (props) => {

  const history = useHistory()

  const expire_sessionsURL = `1`;
  const { logoutLMSActionCreator } = props;
  const { logoutLMSAction } = logoutLMSActionCreator;

  const handleLogout = () => {
    logoutLMSAction(expire_sessionsURL);
    localStorage.removeItem("checkLogin");
    history.push('/');
  }

  const menu = (
    <Menu>
      <Menu.Item onClick={() => handleLogout()}>
      <LogoutOutlined /> Đăng xuất
      </Menu.Item>
    </Menu>
  );

  const DropdownMenu = () => {
    return (
      <Dropdown key="more" overlay={menu}>
        <Button
          style={{
            border: 'none',
            padding: 0,
          }}
        >
          <UserAddOutlined
            style={{
              width: 50,
              fontSize: 20,
              verticalAlign: 'top',
            }} />
        </Button>
      </Dropdown>
    );
  };

  return (
    <PageHeader
      className="site-page-header-responsive"
      //onBack={() => window.history.back()}
      title={props.name}
      extra={[
        <DropdownMenu key="more" />,
      ]}
    >
    </PageHeader>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    logoutLMSActionCreator: bindActionCreators(logoutLMSAction, dispatch)
  }
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect)(Header);
