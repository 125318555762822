import * as coursesConstants from '../constants/courses';


const initialState = {
  dataCourses: [],
  dataCoursesOnly: [],
  totalStudents: null,
  totalStudentsOnly: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case coursesConstants.GET_COURSES: {
      return {
        ...state,
      }
    }

    case coursesConstants.GET_COURSES_SUCCESS: {
      const { data } = action.payload;

      var countStudents = data.reduce((accum,item) => accum + item.total_students, 0)

      return {
        dataCourses: data,
        totalStudents: countStudents
      }
    }

    case coursesConstants.GET_COURSES_FAILED: {
      const { error } = action.payload;
      console.log(error)
      return {
        ...state,
      }
    }

    default:
      return state;
  }
}
export default reducer;
