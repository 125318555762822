import axiosService from '../commons/axiosService';
import { API_ENDPOINT_LMS } from '../constants';

export const recentStudents = (data) => {

  const token = JSON.parse(localStorage.getItem('checkLogin'));
  const url = `api/v1/courses/${data.idCourses}/recent_students?per_page=1000`;

  const access_token = token ? token.access_token : null;

  const config = {
      headers: { Authorization: `Bearer ${access_token}` }
  };

  return axiosService.getConfig(`${API_ENDPOINT_LMS}/${url}`, data, config)
}
