import * as coursesSubmissions from '../constants/coursesSubmissions';

export const coursesSubmissionsAction = (id) => {
  return {
    type: coursesSubmissions.GET_COURSES_SUBMISSIONS,
    payload: {
      id,
    }
  }
};

export const coursesSubmissionsActionSuccess = (data) => {
  return {
    type: coursesSubmissions.GET_COURSES_SUBMISSIONS_SUCCESS,
    payload: { data }
  }
};

export const coursesSubmissionsActionFailed = (error) => {
  return {
    type: coursesSubmissions.GET_COURSES_SUBMISSIONS_FAILED,
    payload: { error }
  }
}