import React from 'react';
import { Provider } from 'react-redux';
import 'antd/dist/antd.css';
import { BrowserRouter as Router, Switch } from "react-router-dom";

import configureStore from '../../redux/configureStore'
import GlobalLoading from '../../components/GlobalLoading';
import ModalHera from '../../components/ModalHera';
import { ADMIN_ROUTES, ROUTES } from '../../constants';
import AdminLayoutRoute from '../../commons/Layout/AdminLayoutRoute';
import DefaultLayoutRoute from '../../commons/Layout/AdminLayoutRoute';

const store = configureStore();

const renderAdminRoutes = () => {
  let xhtml = null;
  xhtml = ADMIN_ROUTES.map(route => {
    return (
      <AdminLayoutRoute
        key={route.key}
        path={route.path}
        component={route.component}
        exact={route.exact}
        name={route.name}
      />
    )
  })
  return xhtml;
}

const renderDefaultRoutes = () => {
  let xhtml = null;
  xhtml = ROUTES.map(route => {
    return (
      <DefaultLayoutRoute
        key={route.key}
        path={route.path}
        component={route.component}
        exact={route.exact}
        name={route.name}
      />
    )
  })
  return xhtml;
}

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <GlobalLoading />
        <ModalHera />
        <Switch>
          {renderAdminRoutes()}
          {renderDefaultRoutes()}
        </Switch>
       </Router>
    </Provider>
  );
}

export default App;
