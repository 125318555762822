import React from 'react';
import './index.scss';
import { Layout } from 'antd';

import Sidebar from './Sidebar';
import Header from './Header';

const { Content, Footer } = Layout;

const Dashboard = (props) => {
  const { children, name } = props;
  const checkCodeUrl = props.location.pathname.indexOf("login");

  return (
    props.location.pathname === "/" ||  checkCodeUrl >= 1 ? children :
    <Layout style={{ minHeight: '100vh' }}>
      <Sidebar props={props} />
      <Layout>
        <Header className="site-layout-sub-header-background" style={{ padding: 0 }} name={name}/>
        <Content style={{ margin: '24px 16px 0' }}>
          <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
            {children}
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>Herapo 2020 </Footer>
      </Layout>
    </Layout>
  )
}

export default Dashboard;
