/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { Form, Button } from 'antd';
import img from '../../images/share-hrp.jpg';
import './index.scss';

import * as loginLMSActions from './../../actions/loginLMS';

const LoginPage = (props) => {
  const code = props.history.location.search.slice(6, 134)

  const { isLogin } = props;

  useEffect(() => {
    const { lmsLoginActionCreators } = props;
    const { loginLMS } = lmsLoginActionCreators;
    loginLMS(code);
    if (localStorage.length === 0) {
      props.history.push("/")
    } else {
      props.history.push("/admin")
    }
  }, [isLogin])

  return (
    <Form
      name="normal_login"
      className="login-form"
    >
      <Form.Item >
        <Button type="primary"
          htmlType="submit"
          className="login-form-button"
          href="https://lms.herapo.net/login/oauth2/auth?client_id=10000000000003&response_type=code&state=YYY&redirect_uri=https://lmsadmin.herapo.net/login"
        >
          <div className="img-login">
            <img src={img} alt="herapo-login"></img>
          </div>
                    Đăng nhập bằng tài khoản LMS
                </Button>
      </Form.Item>
    </Form>
  )
}

const mapStateToProps = state => {
  return {
    isLogin: state.loginLMS.isLogin,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    lmsLoginActionCreators: bindActionCreators(loginLMSActions, dispatch)
  }
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(LoginPage)
