import React from 'react'
import { Input } from 'antd';

export default function SearchTable(props) {
  const { Search } = Input;

  return (
    <Search
      placeholder="Tìm kiếm lớp học"
      onChange={event => {
        props.onFilter(event.target.value);
      }}
    />
  )
}
