import * as coursesSubmissionsConstants from '../constants/coursesSubmissions';

const initialState = {
  dataCoursesSubmissions: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case coursesSubmissionsConstants.GET_COURSES_SUBMISSIONS:
      return { ...state }

    case coursesSubmissionsConstants.GET_COURSES_SUBMISSIONS_SUCCESS:
      const { data } = action.payload;
      return {
        ...state,
        dataCoursesSubmissions: data,
      }

    case coursesSubmissionsConstants.GET_COURSES_SUBMISSIONS_FAILED:
      const { error } = action.payload;
      console.log('Courses Submissions Reducer', error);
      return { ...state }

    default:
      return state;
  }
}

export default reducer;